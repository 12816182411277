import React from 'react'
import style from './MainBlock.module.css'
import Header from './Header/Header'
import { ReactComponent as ArrowIcon } from '../../Assets/Icons/arrowIcon.svg'
import { Link } from 'react-scroll'

const MainBlock = () => {
  return (
    <div className={style.bg} id={'start'}>
      <Header />
      <div className={`customContainer ${style.wrap}`}>
        <h1 className={style.wrap_title}>
          Добре, коли <br />
          ти вдома
        </h1>
        <h5 className={style.wrap_subtitle}>
          Купуйте житло у сучасному та комфортному ЖК в рідному Бориславі.
        </h5>
        <a href={'tel:+380 50 077 07 10'} className={style.button}>
          Зв'язатися з менеджером
        </a>
        <Link
          to={'sabBuilding'}
          smooth={true}
          duration={300}
          className={style.wrap_bottom}
        >
          <span className={style.wrap_bottom__txt}>Гортай далі</span>{' '}
          <ArrowIcon />
        </Link>
      </div>
    </div>
  )
}

export { MainBlock }
